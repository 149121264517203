export class PageHeadingConstants {
  static typeHP = "HP";
  static typeOP = "OP";
  static typeSP = "SP";

  static readonly MAP_LINK_W_LEFT_MENU = new Map([
    // [Link page Detail, link page active]
    ['/media-center/now-apielgo-detail','/media-center/now-apielgo'],
    ['/media-center/last-broadcast-detail','/media-center/last-broadcast'],
    ['/business/story-details','/business/story'],
    ['/serviceCenter/notice-detail','/serviceCenter/notice'],
    ['/serviceCenter/customer-consultation-guide-composing','/serviceCenter/qna'],
    ['/serviceCenter/customer-consultation-guide-detail','/serviceCenter/qna'],
    ['/servicePage/archives-board-detail','/serviceCenter/archives'],
    ['/serviceCenter/schedule-detail','/serviceCenter/schedule'],
    ['/servicePage/last-broadcast-detail','/media-center/last-broadcast'],
    ['/servicePage/now-apielgo-detail','/media-center/now-apielgo'],
    ['/servicePage/hall-of-fame-detail','/business/hall-of-fame'],
    ['/my-office/notice/news-detail','/my-office/notice/news'],
    ['/my-office/notice/library-detail','/my-office/notice/library'],
  ])
}